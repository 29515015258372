import React from "react";
import { ValueSet } from "../../types/ContactTypes";
import { StyledIconTypograhyText } from "../styledcomponents/StyledIconTypograhyText";
import { Grid, IconButton } from "@mui/material";
import ChatIcon from "../../assets/svg/phone-icon.svg";

type URLComponentProps = {
  urls: ValueSet[] | null | undefined;
};

export default function URLComponent(linkComponentProps: URLComponentProps) {
  const { urls } = linkComponentProps;

  const urlClick = (url: string | null) => {
    if (url) window.open(url);
  };

  return (
    <>
      {urls?.map((url: ValueSet, index: number) => {
        return (
          <Grid
            item
            md={4}
            sm={4}
            xs={4}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            key={index}
          >
            <IconButton
              size="small"
              disableRipple
              onClick={() => urlClick(url.value)}
            >
              <img src={ChatIcon} alt="Link" width={70} height={70} />
            </IconButton>
            <StyledIconTypograhyText>{url.name}</StyledIconTypograhyText>
          </Grid>
        );
      })}
    </>
  );
}
