import { all } from "redux-saga/effects";
import * as contactSaga from "./contactSaga";

export default function* rootSaga() {
  yield all([
    contactSaga.fetchContactDetailSaga(),
    contactSaga.sendSyncFormDetailSaga(),
    contactSaga.fetchVCFFileSaga(),
  ]);
}
