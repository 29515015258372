import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SmartCardData } from "../types/ContactTypes";
import { SyncFormType } from "../types/SyncFormType";
import { ImagesList } from "../types/ImageTypes";
import { downloadTxtFile } from "../utils/util";

interface ContactState {
  contactDetails: SmartCardData;
  loading: boolean;
  syncForm: {
    openDrawer: boolean;
    syncFormDetails: SyncFormType;
  };
  imagesList: ImagesList;
  VCFData: string;
}

export const initialState: ContactState = {
  contactDetails: {
    id: null,
    imagesList: null,
    personal: null,
    general: null,
    business: null,
    payment: null,
    messaging: null,
    smartCardSitting: null,
    social: null,
  },
  loading: true,
  syncForm: {
    openDrawer: false,
    syncFormDetails: {
      name: "",
      email: "",
      message: "",
    },
  },
  imagesList: {
    companyLogo: {
      imageStream: "",
      imageType: null,
    },
    profilePicture: {
      imageStream: "",
      imageType: null,
    },
    coverPhoto: {
      imageStream: "",
      imageType: null,
    },
  },
  VCFData: "",
};

const contactSlice = createSlice({
  name: "contact",
  initialState: initialState,
  reducers: {
    fetchContactDetail: (
      state: ContactState,
      action: PayloadAction<string>
    ) => {
      state.loading = true;
    },
    setContactDetail: (
      state: ContactState,
      action: PayloadAction<SmartCardData>
    ) => {
      state.contactDetails = { ...action.payload };
      state.loading = false;
    },
    setImageList: (state: ContactState, action: PayloadAction<ImagesList>) => {
      state.imagesList = { ...action.payload };
    },
    openDrawerForm: (state: ContactState) => {
      state.syncForm = {
        ...state.syncForm,
        openDrawer: true,
      };
    },
    closeDrawerForm: (state: ContactState) => {
      state.syncForm = {
        ...state.syncForm,
        openDrawer: false,
      };
    },
    sendSyncFormDetail: (state: ContactState, action: PayloadAction<any>) => {
      state.loading = true;
    },
    sendSyncFormDetailSuccess: (state: ContactState) => {
      state.loading = false;
      state.syncForm = {
        ...state.syncForm,
        openDrawer: false,
      };
    },
    sendSyncFormDetailFailure: (state: ContactState) => {
      state.loading = false;
      state.syncForm = {
        ...state.syncForm,
        openDrawer: false,
      };
    },
    fetchVCFData: (state: ContactState, action: PayloadAction<string>) => {
      state.loading = true;
    },
    setVCFData: (state: ContactState, action: PayloadAction<any>) => {
      state.loading = false;
      state.VCFData = action.payload;
    },
    downloadVCF: (state: ContactState) => {
      downloadTxtFile([
        state.VCFData,
        `${state.contactDetails.personal?.firstName} ${state.contactDetails.personal?.lastName}`,
      ]);
    },
  },
});

export const {
  fetchContactDetail,
  setContactDetail,
  setImageList,
  openDrawerForm,
  closeDrawerForm,
  sendSyncFormDetail,
  sendSyncFormDetailSuccess,
  sendSyncFormDetailFailure,
  fetchVCFData,
  setVCFData,
  downloadVCF,
} = contactSlice.actions;

export default contactSlice.reducer;
