import React from "react";
import { ValueSet } from "../../types/ContactTypes";
import { StyledIconTypograhyText } from "../styledcomponents/StyledIconTypograhyText";
import { Grid, IconButton } from "@mui/material";

type SocialComponentProps = {
  social: ValueSet | null | undefined;
  icon: string | undefined;
};

export default function SocialComponent(
  socialComponentProps: SocialComponentProps
) {
  const { social, icon } = socialComponentProps;

  const socialClick = (social: string | null | undefined) => {
    if (social) window.open(social, "_blank");
  };

  return (
    <>
      {social && (
        <Grid
          item
          md={4}
          sm={4}
          xs={4}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
        >
          <IconButton
            size="small"
            disableRipple
            onClick={() => socialClick(social?.value)}
          >
            <img src={icon} alt={social?.name || ""} width={70} height={70} />
          </IconButton>
          <StyledIconTypograhyText>{social?.name}</StyledIconTypograhyText>
        </Grid>
      )}
    </>
  );
}
