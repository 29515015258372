import { useEffect, useRef } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/store-hooks";
import {
  closeDrawerForm,
  fetchContactDetail,
  fetchVCFData,
  openDrawerForm,
} from "../../state/contactSlice";
import { RootState } from "../../app/store";
import { useParams } from "react-router-dom";

import DrawerWrapper from "../../components/DrawerWrapper";
import SyncForm from "./SyncForm";
import CardTemplate1 from "../../components/cardtemplates/CardTemplate1";
import CardTemplate2 from "../../components/cardtemplates/CardTemplate2";

export default function Card() {
  const animationStartingPosition = 10;
  const bannerImageRef = useRef<any>();
  const profileContainerRef = useRef<any>();
  const detailContainerRef = useRef<any>();
  const profileImageRef = useRef<any>();
  const logoImageRef = useRef<any>();
  const bgProfileImageRef = useRef<any>();
  const dispatch = useAppDispatch();
  const {
    contactDetails,
    loading,
    syncForm: { openDrawer },
    imagesList,
  } = useAppSelector((state: RootState) => state.contact);
  let { id } = useParams();

  const handleClose = (): void => {
    dispatch(closeDrawerForm());
  };

  const handleSync = (): void => {
    dispatch(openDrawerForm());
  };

  const setJustifyContent = () => {
    return imagesList?.companyLogo?.imageStream != null
      ? "space-between"
      : "flex-start";
  };

  const handleSaveClick = () => {
    dispatch(fetchVCFData(id as string));
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      detailContainerRef.current.className =
        window.scrollY > animationStartingPosition
          ? "hide-detail"
          : "show-detail";
      profileImageRef.current.className =
        window.scrollY > animationStartingPosition
          ? "profile-picture profile-shrink"
          : "profile-picture profile-expand";
      bgProfileImageRef.current.className =
        window.scrollY > animationStartingPosition
          ? "profile-image-background profileBg-shrink"
          : "profile-image-background profileBg-expand";
      logoImageRef.current.className =
        window.scrollY > animationStartingPosition
          ? "logo-picture logo-shrink"
          : "logo-picture logo-expand";
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  useEffect(() => {
    dispatch(fetchContactDetail(id as string));
  }, [dispatch, id]);

  return (
    <div className="contact-card">
      {contactDetails.smartCardSitting &&
      (contactDetails.smartCardSitting.defaultTemplateName === "Basic01" ||
        contactDetails.smartCardSitting.defaultTemplateName === "Basic03") ? (
        <CardTemplate1
          imagesList={imagesList}
          bannerImageRef={bannerImageRef}
          profileContainerRef={profileContainerRef}
          setJustifyContent={setJustifyContent}
          bgProfileImageRef={bgProfileImageRef}
          profileImageRef={profileImageRef}
          loading={loading}
          contactDetails={contactDetails}
          logoImageRef={logoImageRef}
          detailContainerRef={detailContainerRef}
          handleSync={handleSync}
          handleSaveClick={handleSaveClick}
        />
      ) : contactDetails.smartCardSitting?.defaultTemplateName === "Basic02" ? (
        <CardTemplate2
          imagesList={imagesList}
          bannerImageRef={bannerImageRef}
          profileContainerRef={profileContainerRef}
          setJustifyContent={setJustifyContent}
          bgProfileImageRef={bgProfileImageRef}
          profileImageRef={profileImageRef}
          loading={loading}
          contactDetails={contactDetails}
          logoImageRef={logoImageRef}
          detailContainerRef={detailContainerRef}
          handleSync={handleSync}
          handleSaveClick={handleSaveClick}
        />
      ) : null}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DrawerWrapper open={openDrawer} onClose={handleClose}>
        <SyncForm />
      </DrawerWrapper>
    </div>
  );
}
