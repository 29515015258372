import React from 'react'
import { ValueSet } from '../../types/ContactTypes';
import { Grid, IconButton } from '@mui/material';
import { StyledIconTypograhyText } from '../styledcomponents/StyledIconTypograhyText';
import OutlookIcon from '../../assets/svg/outlook-icon.svg'

type EmailComponentProps = {
    emails: ValueSet[] | null | undefined;
}

export default function EmailComponent(emailComponentProps: EmailComponentProps) {
    const { emails } = emailComponentProps;

    const emailClick = (email: string | null) => {
        if (email)
            window.open(`mailto:${email}`);
    }

    return (
        <>
            {
                emails?.map((email: ValueSet, index: number) => {
                    return (
                        <Grid item md={4} sm={4} xs={4} display='flex' flexDirection='column' justifyContent='flex-start' key={index}>
                            <IconButton size='small' disableRipple onClick={() => emailClick(email.value)}>
                                <img src={OutlookIcon} alt="Email" width={70} height={70} />
                            </IconButton>
                            <StyledIconTypograhyText>{email.name}</StyledIconTypograhyText>

                        </Grid>
                    )
                })
            }
        </>
    )
}