import React from "react";

import { Avatar, Box, Grid, Typography } from "@mui/material";
import CardCommonApps from "./CardCommonApps";
import { textTrucateWithLength } from "../../utils/util";
import CardBottom from "./CardComponents/CardBottom";
import Banner from "../../assets/images/Banner-Template-2.png";
import PhoneOutlineIcon from "../../assets/svg/phoneoutline-icon.svg";
import EmailOutlineIcon from "../../assets/svg/emailoutline-icon.svg";
import PoweredBy from "../../assets/svg/poweredByImage.svg";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

function CardTemplate2(props: any) {
  const {
    imagesList,
    bannerImageRef,
    profileContainerRef,
    setJustifyContent,
    bgProfileImageRef,
    profileImageRef,
    loading,
    contactDetails,
    logoImageRef,
    detailContainerRef,
    handleSaveClick,
    handleSync,
  } = props;

  return (
    <>
      <div className="headerSection">
        <div className="card-header">
          {imagesList &&
          imagesList.coverPhoto &&
          imagesList.coverPhoto.imageStream ? (
            <img
              src={`data:${imagesList?.coverPhoto?.imageType};base64,${imagesList?.coverPhoto?.imageStream}`}
              alt="coverImage"
              className="bannerImage"
            />
          ) : (
            <>
              <img
                src={Banner}
                alt="bannerImage"
                ref={bannerImageRef}
                className="bannerImageTemplate2"
              />
              <img
                src={PoweredBy}
                onClick={() => {
                  window.open(process.env.REACT_APP_MARKETING_PAGE_LINK);
                }}
                alt="poweredByImage"
                className="poweredByImage"
              />
            </>
          )}
        </div>
        <div className="profile-container-Template2" ref={profileContainerRef}>
          <Grid
            container
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={setJustifyContent()}
          >
            <div className="profile-image-background" ref={bgProfileImageRef}>
              {imagesList?.profilePicture?.imageStream ? (
                <img
                  className="profile-picture"
                  alt="profile"
                  src={`data:${imagesList?.profilePicture?.imageType};base64,${imagesList?.profilePicture?.imageStream}`}
                  ref={profileImageRef}
                />
              ) : (
                <>
                  {!loading && (
                    <Avatar
                      className="profile-picture"
                      sx={{ width: 124, height: 124 }}
                    />
                  )}
                </>
              )}
              {contactDetails &&
                contactDetails.smartCardSitting &&
                contactDetails.smartCardSitting.isProfileVerified && (
                  <Box
                    sx={{
                      width: "2rem",
                      height: "2rem",
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      position: "absolute",
                      bottom: "5px",
                      right: "5px",
                    }}
                  >
                    <CheckCircleOutlinedIcon
                      sx={{ color: "blue", fontSize: "2rem" }}
                    />
                  </Box>
                )}
            </div>

            {imagesList?.companyLogo?.imageStream ? (
              <img
                className="logo-picture"
                alt="logo"
                src={`data:${imagesList?.companyLogo?.imageType};base64,${imagesList?.companyLogo?.imageStream}`}
                ref={logoImageRef}
              />
            ) : null}
          </Grid>
          <Grid marginBottom={1} container>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                component="span"
                sx={{
                  fontFamily: "Rubik",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "28px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                {contactDetails.personal?.firstName}
                {contactDetails.personal?.lastName}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                component="span"
                sx={{
                  fontFamily: "Rubik",
                  fontSize: "15px",
                  fontWeight: "400",
                  lineHeight: "18px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                {contactDetails.business?.jobTitle &&
                  contactDetails.business?.businessName && (
                    <>
                      {contactDetails.business?.jobTitle} at
                      {contactDetails.business?.businessName}
                    </>
                  )}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              {!loading && (
                <Typography
                  component="span"
                  sx={{
                    color: " #8891B0",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "18px",
                    fontFamily: "Rubik",
                    letterSpacing: "0em",
                    textAlign: "left",
                  }}
                >
                  {contactDetails.business?.department}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container ref={detailContainerRef}>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                color: "#4E597F",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                marginBottom: "6px",
              }}
            >
              {!loading && <img src={PhoneOutlineIcon} alt="Phone" />}
              <span
                style={{
                  paddingTop: "6px",
                  paddingLeft: "12px",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                {contactDetails.general?.phones &&
                  contactDetails.general?.phones?.length > 0 && (
                    <>{contactDetails.general?.phones[0].value}</>
                  )}
              </span>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                component="span"
                sx={{
                  color: "#1F2A51",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {textTrucateWithLength(contactDetails.business?.about, 90)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="invisibleDivTemplate2"></div>
      <div className="commonAppContainerTepm2">
        <div
          style={{
            display: "grid",
            gap: "1.5rem",
            gridTemplateColumns: "auto auto auto",
          }}
        >
          <CardCommonApps contactDetails={contactDetails} />
        </div>
      </div>
      <CardBottom
        loading={loading}
        handleSaveClick={handleSaveClick}
        handleSync={handleSync}
      />
    </>
  );
}

export default CardTemplate2;
