import React from "react";
import { ValueSet } from "../../types/ContactTypes";
import { StyledIconTypograhyText } from "../styledcomponents/StyledIconTypograhyText";
import { Grid, IconButton } from "@mui/material";
import YoutubeIcon from "../../assets/svg/youtube-icon.svg";
type LinkComponentProps = {
  links: ValueSet[] | null | undefined;
};

export default function LinkComponent(linkComponentProps: LinkComponentProps) {
  const { links } = linkComponentProps;

  const linkClick = (link: string | null) => {
    if (link) window.open(link);
  };

  return (
    <>
      {links?.map((link: ValueSet, index: number) => {
        if (link.name && link.value) {
          return (
            <Grid
              item
              md={4}
              sm={4}
              xs={4}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              key={index}
            >
              <IconButton
                size="small"
                disableRipple
                onClick={() => linkClick(link.value)}
              >
                <img src={YoutubeIcon} alt="Link" width={70} height={70} />
              </IconButton>
              <StyledIconTypograhyText>{link.name}</StyledIconTypograhyText>
            </Grid>
          );
        }
      })}
    </>
  );
}
