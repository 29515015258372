import VCard from "vcard-creator";

export const downloadTxtFile = (prop: any) => {
  const element = document.createElement("a");
  const file = new Blob([prop[0]], { type: "text/x-vCard;charset=iso-8859-1" });
  element.href = window.URL.createObjectURL(file);
  element.download = `${prop[1]}.vcf`;
  document.body.appendChild(element);
  element.click();
};

export const createVCard = (cardDetails: any, imageDetails: any) => {
  if (cardDetails && Object.keys(cardDetails).length > 0) {
    const vCard = new VCard();
    const { firstName, lastName, prefix, suffix, address } =
      cardDetails.personal;
    const { businessName, jobTitle } = cardDetails.business;
    const { emails, phones, urls } = cardDetails.general;

    const fName = firstName ? firstName : "";
    const lName = lastName ? lastName : "";
    const pre = prefix ? prefix : "";
    const suff = suffix ? suffix : "";

    vCard
      //Personal Data
      .addName(lName, fName, "", pre, suff)
      //Work Data
      .addCompany(businessName)
      .addJobtitle(jobTitle);
    if (emails && Array.isArray(emails) && emails.length > 0) {
      emails.forEach((email) => {
        vCard.addEmail(email.value, "WORK");
      });
    }
    if (phones && Array.isArray(phones) && phones.length > 0) {
      phones.forEach((phone) => {
        vCard.addPhoneNumber(phone.value, "WORK");
      });
    }
    if (urls && Array.isArray(urls) && urls.length > 0) {
      urls.forEach((site) => {
        vCard.addURL(site.value, site.name);
      });
    }
    if (address && Object.keys(address).length > 0) {
      vCard.addAddress(
        "",
        "",
        "",
        address.city,
        "",
        address.zip,
        address.country,
        "PERSONAL"
      );
    }
    if (cardDetails.social && Object.keys(cardDetails.social).length > 0) {
      Object.keys(cardDetails.social).forEach((key) => {
        const socialMedia = cardDetails.social[key];
        if (socialMedia && socialMedia !== null) {
          vCard.addSocial(socialMedia.value, socialMedia.name, "");
        }
      });
    }

    return [vCard.toString(), `${firstName} ${lastName}`];
  }
};

export const textTrucateWithLength = (text: string = "", size: number) => {
  if (text && text.length > size) {
    let truncatedString = "";
    truncatedString = text.slice(0, size);
    if (truncatedString && truncatedString !== "") {
      truncatedString += "...";
    }
    return truncatedString;
  }
  return text;
};
