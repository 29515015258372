import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  downloadVCF,
  fetchContactDetail,
  fetchVCFData,
  initialState as initialContactState,
  sendSyncFormDetail,
  sendSyncFormDetailFailure,
  sendSyncFormDetailSuccess,
  setContactDetail,
  setImageList,
  setVCFData,
} from "../state/contactSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { SmartCardData } from "../types/ContactTypes";
import { ImagesList } from "../types/ImageTypes";
import { AxiosResponse } from "axios";
import {
  getContactDetails,
  getContactImageData,
  getVCFFileData,
  postSyncFormDetail,
} from "../services/contact-service";
import { SyncFormType } from "../types/SyncFormType";
import { store } from "../app/store";
import { showSnackbarMessage } from "../state/snackbarSlice";

//interface
interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

// Sagas
export function* fetchContactDetailSaga() {
  yield takeLatest(fetchContactDetail.type, handleFetchContactDetail);
}

export function* sendSyncFormDetailSaga() {
  yield takeLatest(sendSyncFormDetail.type, handleSendSyncFormDetail);
}

export function* fetchVCFFileSaga() {
  yield takeLatest(fetchVCFData.type, getVCFFile);
}

//Handler

function* handleFetchContactDetail(action: PayloadAction<string>) {
  const [smartCardDataResponse, imagesListResponse]: [
    AxiosResponse<Array<SmartCardData>>,
    AxiosResponse<{ imagesList: ImagesList }>
  ] = yield all([
    call(getContactDetails, action.payload),
    call(getContactImageData, action.payload),
  ]);
  yield put(
    setContactDetail(
      smartCardDataResponse.data.length > 0
        ? smartCardDataResponse.data[0]
        : initialContactState.contactDetails
    )
  );
  yield put(setImageList(imagesListResponse.data.imagesList));
}

function* handleSendSyncFormDetail(action: PayloadAction<any>) {
  try {
    yield call(postSyncFormDetail, action.payload);
    yield put(sendSyncFormDetailSuccess());
    yield put(showSnackbarMessage({ message: "Contact shared successfully" }));
  } catch (err: any) {
    const { dispatch } = store;
    dispatch(sendSyncFormDetailFailure());
    dispatch(
      showSnackbarMessage({
        message: "Error while sharing Contact detail",
        action: "error",
      })
    );
  }
}

function* getVCFFile(action: PayloadAction<any>) {
  try {
    const VCFData: ResponseGenerator = yield call(
      getVCFFileData,
      action.payload
    );
    yield put(setVCFData(VCFData?.data));
    yield put(downloadVCF());
    yield put(
      showSnackbarMessage({
        message: "Contact details downloaded successfully",
        severity: "success",
      })
    );
  } catch (err: any) {
    const { dispatch } = store;
    console.log(err, "error");
    dispatch(
      showSnackbarMessage({
        message: "Error on getting data",
        action: "error",
      })
    );
  }
}
