import { configureStore } from "@reduxjs/toolkit";
import contactReducer from "../state/contactSlice";
import snackbarReducer from "../state/snackbarSlice"
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/rootSaga";


const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        contact: contactReducer,
        snackbar: snackbarReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

// then run the saga
sagaMiddleware.run(rootSaga)


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch