import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { theme } from './styles/themes';
import AppRoutes from './routes/AppRoutes';
import { Provider } from 'react-redux';
import { store } from './app/store';


function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider >
    </Provider>
  );
}

export default App;
