import { createTheme } from "@mui/material";

export const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "*::-webkit-scrollbar": {
                    backgroundColor: "#FFF",
                    width: '12px',
                    height: '12px'
                },
                "*::-webkit-scrollbar-track": {
                    background: "#f8f8f8",
                    borderRadius: "8px"
                },
                "*::-webkit-scrollbar-thumb": {
                    backgroundColor: '#babac0',
                    borderRadius: '16px',
                    border: '4px solid #fff',
                    minHeight: '40px',
                    '&:hover': {
                        backgroundColor: '#babac0',
                        border: '2px solid #fff',
                    },
                }
            }
        }
    }

})