import React from 'react'
import { ValueSet } from '../../types/ContactTypes';
import { StyledIconTypograhyText } from '../styledcomponents/StyledIconTypograhyText';
import { Grid, IconButton } from '@mui/material';


type PaymentComponentProps = {
    payment: ValueSet | null | undefined;
    icon: string | undefined
}

export default function PaymentComponent(paymentComponentProps: PaymentComponentProps) {
    const { payment, icon } = paymentComponentProps;

    const paymentClick = (payment: string | null | undefined) => {
        if (payment)
            window.open(payment);
    }

    return (
        <>
            {
                payment && <Grid item md={4} sm={4} xs={4} display='flex' flexDirection='column' justifyContent='flex-start' >
                    <IconButton size='small' disableRipple onClick={() => paymentClick(payment?.value)}>
                        <img src={icon} alt={payment?.name ?? ''} width={70} height={70} />
                    </IconButton>
                    <StyledIconTypograhyText>{payment?.name}</StyledIconTypograhyText>
                </Grid>
            }
        </>

    )
}