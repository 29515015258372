import React from 'react'
import { ValueSet } from '../../types/ContactTypes';
import { StyledIconTypograhyText } from '../styledcomponents/StyledIconTypograhyText';
import { Grid, IconButton } from '@mui/material';


type MessageComponentProps = {
    message: ValueSet | null | undefined;
    icon: string | undefined
}

export default function MessageComponent(messageComponentProps: MessageComponentProps) {
    const { message, icon } = messageComponentProps;

    const messageClick = (message: string | null | undefined) => {
        if (message)
            window.open(message);
    }

    return (
        <>
            {
                message && <Grid item md={4} sm={4} xs={4} display='flex' flexDirection='column' justifyContent='flex-start' >
                    <IconButton size='small' disableRipple onClick={() => messageClick(message?.value)}>
                        <img src={icon} alt={message?.name || ''} width={70} height={70} />
                    </IconButton>
                    <StyledIconTypograhyText>{message?.name}</StyledIconTypograhyText>
                </Grid>
            }
        </>

    )
}