import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout';
import Card from '../pages/Card/Card';
import ErrorPage from '../pages/ErrorPage';
import SnackbarWrapper from '../components/Snackbar';

export default function AppRoutes() {
    return (
        <>
            <SnackbarWrapper />
            <BrowserRouter basename='/'>
                <Layout>
                    <Routes>
                        <Route path='/:id' element={<Card />} />
                        <Route path='*' element={<ErrorPage />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </>
    )
}