import { StyledIconTypograhyText } from "../styledcomponents/StyledIconTypograhyText";
import { Grid, IconButton } from "@mui/material";
import ChatIcon from "../../assets/svg/address-icon.svg";
import { ValueSet } from "../../types/ContactTypes";

type aboutComponentProps = {
  address: ValueSet[] | null | undefined;
};

export default function AboutComponent(about: aboutComponentProps) {
  const { address } = about;
  const aboutClick = (address: string | null) => {
    if (address) window.open(`${address}`);
  };
  return (
    <>
      {address?.map((val: any, ind: any) => {
        return (
          <Grid
            item
            md={4}
            sm={4}
            xs={4}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            key={ind}
          >
            <IconButton
              size="small"
              disableRipple
              onClick={() => {
                aboutClick(val?.value);
              }}
            >
              <img src={ChatIcon} alt="Link" width={70} height={70} />
            </IconButton>
            <StyledIconTypograhyText>{val?.name}</StyledIconTypograhyText>
          </Grid>
        );
      })}
    </>
  );
}
