import { CssBaseline } from "@mui/material";
import '../styles/main.css';

type LayoutProps = {
    children: React.ReactNode
}



export default function Layout(layoutProps: LayoutProps) {
    const { children } = layoutProps;
    return (
        <>
            <CssBaseline />
            <div className="card-page">
                {children}
            </div>
        </>
    )
}