import { Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import SyncOutlinedIcon from "../../../assets/svg/sync.svg";
import GetAppOutlinedIcon from "../../../assets/svg/download-icon.svg";

function CardBottom(props: any) {
  const { loading, handleSaveClick, handleSync } = props;

  return (
    <>
      {!loading && (
        <div className="action-control-container">
          <Grid
            container
            sx={{ padding: "24px", paddingTop: "48px", paddingBottom: "12px" }}
            flexDirection="row"
            justifyContent="center"
            alignContent="cen"
          >
            <Grid item md={8} sm={8} xs={8}>
              <Button
                className="save-button"
                fullWidth
                onClick={handleSaveClick}
              >
                <Typography component="span" className="save-button-text">
                  Save Contact
                </Typography>
              </Button>
            </Grid>
            <Grid item md={2} sm={2} xs={2}>
              <IconButton
                size="medium"
                className="sync-button"
                sx={{
                  color: "white",
                  backgroundColor: "#1EC171",
                  borderRadius: "10px !important",
                  height: "48px",
                  width: "48px",
                  marginLeft: "10px",
                  "&:hover": {
                    backgroundColor: "#1EC171",
                  },
                }}
                onClick={handleSync}
              >
                <img src={SyncOutlinedIcon} alt="syncImage" />
              </IconButton>
            </Grid>
            <Grid item md={2} sm={2} xs={2}>
              <IconButton
                size="medium"
                className="download-button"
                sx={{
                  color: "white",
                  backgroundColor: "#75b4e1",
                  borderRadius: "10px !important",
                  height: "48px",
                  width: "48px",
                  marginLeft: "12px",
                  "&:hover": {
                    backgroundColor: "#75b4e1",
                  },
                }}
              >
                <img src={GetAppOutlinedIcon} alt="downloadImage" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default CardBottom;
