import { Drawer } from '@mui/material';
import React, { PropsWithChildren } from 'react'

type DrawerWrapperProps = {
    open: boolean
    onClose: () => void;
}

export default function DrawerWrapper(drawerWrapperProps: PropsWithChildren<DrawerWrapperProps>) {
    const { open, onClose, children } = drawerWrapperProps;
    const handleClose = () => {
        onClose();
    }
    return (
        <Drawer
            anchor='bottom'
            open={open}
            onClose={handleClose}
            hideBackdrop={false}
        >
            {children}
        </Drawer>
    )
}