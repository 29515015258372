import axios, { AxiosResponse } from "axios";
import { SyncFormType } from "../types/SyncFormType";

const REACT_APP_API_URL = `https://smartcardapi.azurewebsites.net`;

const API_URL = `${REACT_APP_API_URL}`;

export const getContactDetails = (contactId: string) => {
  const url = new URL(`${API_URL}/smartCardDataItems/${contactId}`);
  return axios.get(url.toString()).then((response: AxiosResponse) => response);
};

export const getContactImageData = (contactId: string) => {
  const url = new URL(`${API_URL}/smartCardImages/${contactId}`);
  return axios.get(url.toString()).then((response: AxiosResponse) => response);
};

export const postSyncFormDetail = (postModel: any) => {
  const url = new URL(`${API_URL}/SyncData`);
  return axios
    .post(url.toString(), postModel)
    .then((response: AxiosResponse) => response);
};

export const getVCFFileData = (userId: string) => {
  const url = new URL(`${API_URL}/VCard/${userId}`);
  return axios.get(url.toString()).then((response: AxiosResponse) => response);
};
