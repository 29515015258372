import { Button, Grid, TextField } from '@mui/material';
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SyncFormType } from '../../types/SyncFormType';
import { useAppDispatch } from '../../app/store-hooks';
import { closeDrawerForm, sendSyncFormDetail } from '../../state/contactSlice';

export default function SyncForm() {
    const dispatch = useAppDispatch();

    const { handleSubmit, control } = useForm<SyncFormType>({
        defaultValues: {
            name: '',
            email: '',
            message: ''
        },
    });

    const handleClose = () => {
        dispatch(closeDrawerForm());
    }

    function CreateGuid() {
        function _p8(s?: boolean) {
            var p = (Math.random().toString(16) + "000000000").substring(2, 8);
            return s ? "-" + p.substring(0, 4) + "-" + p.substring(4, 4) : p;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    const onSubmit: SubmitHandler<SyncFormType> = (data) => {
        dispatch(sendSyncFormDetail({
            id: CreateGuid(),
            name: data.name,
            fromemail: 'gpafoodsllc@gmail.com',
            toemail: data.email,
            message: data.message
        }));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} style={{ padding: '12px' }}>
                    <Grid item md={12} sm={12} xs={12}>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field, fieldState: { error } }) => <TextField
                                {...field}
                                error={Boolean(error?.message)}
                                helperText={error?.message}
                                size='small'
                                placeholder='Name'
                                variant='filled'
                                fullWidth
                            />}
                        />

                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field, fieldState: { error } }) => <TextField
                                {...field}
                                error={Boolean(error?.message)}
                                helperText={error?.message}
                                size='small'
                                placeholder="Email"
                                variant='filled'
                                fullWidth
                            />}
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Controller
                            name="message"
                            control={control}
                            render={({ field, fieldState: { error } }) =>
                                <TextField
                                    {...field}
                                    error={Boolean(error?.message)}
                                    helperText={error?.message}
                                    placeholder='Message'
                                    multiline
                                    maxRows={4}
                                    rows={3}
                                    size='small'
                                    variant='filled'
                                    fullWidth
                                />
                            }
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item> <Button type="submit">Submit</Button></Grid>
                            <Grid item> <Button type="button" onClick={handleClose}>Close</Button></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div >
    )
}