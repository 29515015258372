import React from "react";
import { ValueSet } from "../../types/ContactTypes";
import { StyledIconTypograhyText } from "../styledcomponents/StyledIconTypograhyText";
import { Grid, IconButton } from "@mui/material";
import PhoneCallIcon from "../../assets/svg/phonecall-icon.svg";

type PhoneComponentProps = {
  phones: ValueSet[] | null | undefined;
};

export default function PhoneComponent(
  phoneComponentProps: PhoneComponentProps
) {
  const { phones } = phoneComponentProps;

  const phoneClick = (phoneNumber: string | null) => {
    if (phoneNumber) window.open(`tel:${phoneNumber}`);
  };

  return (
    <>
      {phones?.map((phone: ValueSet, index: number) => {
        return (
          <Grid
            item
            md={4}
            sm={4}
            xs={4}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            key={index}
          >
            <IconButton
              size="small"
              disableRipple
              onClick={() => phoneClick(phone.value)}
            >
              <img src={PhoneCallIcon} alt="Phone" width={70} height={70} />
            </IconButton>
            <StyledIconTypograhyText>{phone.name}</StyledIconTypograhyText>
          </Grid>
        );
      })}
    </>
  );
}
