import PhoneComponent from "../iconcomponents/PhoneComponent";
import EmailComponent from "../iconcomponents/EmailComponent";
import LinkComponent from "../iconcomponents/LinkComponent";
import SocialComponent from "../iconcomponents/SocialComponent";
import URLComponent from "../iconcomponents/URLComponent";
import MessageComponent from "../iconcomponents/MessageComponent";
import PaymentComponent from "../iconcomponents/PaymentComponent";

import TiktokIcon from "../../assets/svg/tiktok-icon.svg";
import WhatsappIcon from "../../assets/svg/whatsapp-icon.svg";
import FacebookIcon from "../../assets/svg/facebook-icon.svg";
import InstagramIcon from "../../assets/svg/instagram-icon.svg";
import CashAppIcon from "../../assets/svg/cashapp-icon.svg";
import TwitterIcon from "../../assets/svg/twitter-icon.svg";
import SnapchatIcon from "../../assets/svg/snapchat-icon.svg";
import YoutubeIcon from "../../assets/svg/youtube-icon.svg";
import VimeoIcon from "../../assets/svg/vimeo-icon.svg";
import LinkedInIcon from "../../assets/svg/linkedin-icon.svg";
import PaypalIcon from "../../assets/svg/paypal-icon.svg";
import TwitchIcon from "../../assets/svg/twitch-icon.svg";
import YelpIcon from "../../assets/svg/yelp-icon.svg";
import DiscordIcon from "../../assets/svg/discord-icon.svg";
import SignalIcon from "../../assets/svg/signal-icon.svg";
import SkypeIcon from "../../assets/svg/skype-icon.svg";
import TelegramIcon from "../../assets/svg/telegram-icon.svg";
import AboutComponent from "../iconcomponents/AboutComponent";

function CardCommonApps(props: any) {
  const { contactDetails } = props;

  return (
    <>
      <PhoneComponent phones={contactDetails.general?.phones} />
      <EmailComponent emails={contactDetails.general?.emails} />
      <LinkComponent links={contactDetails.general?.links} />
      <URLComponent urls={contactDetails.general?.urls} />
      <AboutComponent address={contactDetails.general?.about} />
      <SocialComponent
        social={contactDetails?.social?.twitter}
        icon={TwitterIcon}
      />
      <SocialComponent
        social={contactDetails?.social?.instagram}
        icon={InstagramIcon}
      />
      <SocialComponent
        social={contactDetails?.social?.linkedIn}
        icon={LinkedInIcon}
      />
      <SocialComponent
        social={contactDetails?.social?.facebook}
        icon={FacebookIcon}
      />
      <SocialComponent
        social={contactDetails?.social?.youTube}
        icon={YoutubeIcon}
      />
      <SocialComponent
        social={contactDetails?.social?.snapChat}
        icon={SnapchatIcon}
      />
      <SocialComponent
        social={contactDetails?.social?.tikTok}
        icon={TiktokIcon}
      />
      <SocialComponent
        social={contactDetails?.social?.twitch}
        icon={TwitchIcon}
      />
      <SocialComponent social={contactDetails?.social?.yelp} icon={YelpIcon} />
      <MessageComponent
        message={contactDetails.messaging?.whatsApp}
        icon={WhatsappIcon}
      />
      <MessageComponent
        message={contactDetails.messaging?.telegram}
        icon={TelegramIcon}
      />
      <MessageComponent
        message={contactDetails.messaging?.discord}
        icon={DiscordIcon}
      />
      <MessageComponent
        message={contactDetails.messaging?.signal}
        icon={SignalIcon}
      />
      <MessageComponent
        message={contactDetails.messaging?.skype}
        icon={SkypeIcon}
      />
      <PaymentComponent
        payment={contactDetails.payment?.cashApp}
        icon={CashAppIcon}
      />
      <PaymentComponent
        payment={contactDetails.payment?.payPal}
        icon={PaypalIcon}
      />
      <PaymentComponent
        payment={contactDetails.payment?.venmo}
        icon={VimeoIcon}
      />
    </>
  );
}

export default CardCommonApps;
